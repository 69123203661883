import React, { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { useRouter } from "next/router";

const Custom404 = () => {
  const router = useRouter();
  const sourceUrl = router.query.source;

  const [countdown, setCountdown] = useState(5); // Start countdown at 5 seconds

  useEffect(() => {
    // Log the referring URL when a user is redirected to the 404 page
    const referrer = document.referrer;
    const currentPath = window.location.href;

    console.log("User was redirected to 404 from:", referrer);
    console.log("Current URL:", currentPath);
    console.log("Source URL:", sourceUrl);
  }, []);

  useEffect(() => {
    // Countdown timer to decrease every second
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer); // Stop countdown at 0
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    // Redirect after 5 seconds
    const redirectTimer = setTimeout(() => {
      router.push("/Inventory");
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [router]);

  return (
    <Result
      status="404"
      title="404"
      subTitle="This item may be out of stock or no longer available."
      extra={
        <div>
          <p>Redirecting you to similar items in {countdown} seconds...</p>
          <Button type="primary" href="/Inventory">
            Back to Home
          </Button>
        </div>
      }
    />
  );
};

export default Custom404;
